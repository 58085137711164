import {
  ActionIcon,
  Box,
  Center,
  Divider,
  RingProgress,
  SegmentedControl,
  Text,
  Title,
} from "@mantine/core";
import {
  IconBookmark,
  IconBuildingStore,
  IconCards,
  IconDiscount2,
  IconFolderPlus,
  IconFolderSymlink,
  IconHeadset,
  IconHome,
  IconLetterCase,
  IconPalette,
  IconPhotoUp,
  IconSparkles,
  IconSquaresSelected,
  IconUserCheck,
  IconUsers,
  IconWriting,
  IconX,
} from "@tabler/icons-react";
import {
  LIFETIME_MODAL_ALWAYS_SHOW_SOURCES,
  LifetimeModalTrigger,
  LoginModalTrigger,
  ModalKind,
  track,
} from "../utils";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoButton } from "./ui/InspoButton";

import { modals, type ContextModalProps } from "@mantine/modals";
import { Fragment, useEffect, useState } from "react";
import { colorSchemes } from "src/utils/colorScheme/palette";
import { fontStats } from "src/utils/typography/configs/fontPairs";
import { useUser } from "../contexts/UserContext";
import { openModalLoginIfNeeded } from "./ModalLogin";
import { InspoBadge } from "./ui/InspoBadge";
import { useFeatureFlagEnabled } from "posthog-js/react";
import posthog from "posthog-js";

const LEMON_SQUEEZY_BASE_URL =
  "https://inspotype.lemonsqueezy.com/checkout/buy/";
const LEMON_SQUEEZY_PRODUCTS = {
  YEARLY_49: "69ab4b16-0fe0-411b-9f1a-d54a6ef309f3",
  YEARLY_97: "0e55300f-f94d-4717-a104-51fdc17f54eb",
  LIFETIME_97: "018b0fe0-66b7-473e-9a63-1dd809ac69e5",
  LIFETIME_197: "e1ab7f41-82d0-4766-b1a5-36303350ecd8",
  TEST_LIFETIME: "8209b2c9-24c5-464f-aadb-ffc73f141b25",
  TEST_YEARLY: "cdd5b0fb-3e75-4211-bce8-0077acbd3661",
};

// 197 deal
const lifetimeUrl = (
  isProd: boolean,
  variant: "lower-tier" | undefined,
  checkoutInfo?: { email?: string; fullName?: string },
) => {
  const { email, fullName } = checkoutInfo ?? {};

  const { productId, amount } = (() => {
    if (!isProd) {
      return { productId: LEMON_SQUEEZY_PRODUCTS.TEST_LIFETIME, amount: "100" };
    }

    if (variant === "lower-tier") {
      return { productId: LEMON_SQUEEZY_PRODUCTS.LIFETIME_97, amount: "97" };
    }

    return { productId: LEMON_SQUEEZY_PRODUCTS.LIFETIME_197, amount: "197" };
  })();

  const urlWithParams = new URL(`${LEMON_SQUEEZY_BASE_URL}${productId}`);
  urlWithParams.searchParams.set("embed", "1");
  email && urlWithParams.searchParams.set("checkout[email]", email);
  fullName && urlWithParams.searchParams.set("checkout[name]", fullName);

  return { link: urlWithParams.toString(), amount };
};

// 97/year
const yearlyUrl = (
  isProd: boolean,
  variant: "lower-tier" | undefined,
  checkoutInfo?: { email?: string; fullName?: string },
  postCheckoutInfo?: { userId?: string },
) => {
  const { email, fullName } = checkoutInfo ?? {};
  const { userId } = postCheckoutInfo ?? {};

  const { productId, amount } = (() => {
    if (!isProd) {
      return { productId: LEMON_SQUEEZY_PRODUCTS.TEST_YEARLY, amount: "10" };
    }

    if (variant === "lower-tier") {
      return { productId: LEMON_SQUEEZY_PRODUCTS.YEARLY_49, amount: "49" };
    }

    return { productId: LEMON_SQUEEZY_PRODUCTS.YEARLY_97, amount: "97" };
  })();

  const urlWithParams = new URL(`${LEMON_SQUEEZY_BASE_URL}${productId}`);
  urlWithParams.searchParams.set("embed", "1");
  email && urlWithParams.searchParams.set("checkout[email]", email);
  fullName && urlWithParams.searchParams.set("checkout[name]", fullName);
  userId && urlWithParams.searchParams.set("checkout[custom][user_id]", userId);

  return { link: urlWithParams.toString(), amount };
};

const LIFETIME_MODAL_TIMER_DURATION_SECONDS = 3.5; // Lock modal for 5 seconds
const LIFETIME_MODAL_THRESHOLD = 10; // Show lifetime modal every nth time

var lifetimeModalIsVisible = false;

export function openModalLifetimeIfNeeded({
  user,
  isPro,
  source,
  additionalTrackProperties,
}: {
  user: any;
  isPro: boolean;
  source: string;
  additionalTrackProperties?: Record<string, any>;
}) {
  if (!user) {
    openModalLoginIfNeeded({
      user,
      isMobile: false,
      source: LoginModalTrigger.LIFETIME_MODAL,
    });
    return false;
  }

  if (isPro) return false;

  const otherModalsVisible =
    source === LifetimeModalTrigger.PRE_LIFETIME_WITH_WAIT_CTA ||
    source === LifetimeModalTrigger.PRE_LIFETIME_CTA ||
    source === LifetimeModalTrigger.CTA;

  if (lifetimeModalIsVisible && !otherModalsVisible) return true;

  const LIFETIME_MODAL_COUNTER_KEY = "lifetime_modal_counter";
  const shouldAlwaysShow = LIFETIME_MODAL_ALWAYS_SHOW_SOURCES.includes(
    source as any,
  );

  if (otherModalsVisible) {
    lifetimeModalIsVisible = true;

    modals.closeAll();

    modals.openContextModal({
      modal: ModalKind.LIFETIME,
      innerProps: {},
      centered: true,
      trapFocus: false,
      autoFocus: false,
      size: "1000px",
      withCloseButton: false,
      padding: 0,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onClose: () => {
        lifetimeModalIsVisible = false;
      },
    });
    return true;
  }

  if (shouldAlwaysShow) {
    lifetimeModalIsVisible = true;
    modals.openContextModal({
      modal: ModalKind.PRE_LIFETIME,
      innerProps: {
        triggerFeatures: source,
      },
      centered: true,
      trapFocus: false,
      autoFocus: false,
      size: "500px",
      withCloseButton: false,
      padding: 0,
      closeOnEscape: true,
      closeOnClickOutside: true,
      onClose: () => {
        lifetimeModalIsVisible = false;
      },
    });
    return true;
  }

  if (!shouldAlwaysShow) {
    const counterStr = localStorage.getItem(LIFETIME_MODAL_COUNTER_KEY) || "0";
    const counter = parseInt(counterStr, 10);

    localStorage.setItem(LIFETIME_MODAL_COUNTER_KEY, (counter + 1).toString());

    const allowEvenIfProAction =
      counter <= 0 || counter % LIFETIME_MODAL_THRESHOLD !== 0;
    if (allowEvenIfProAction || lifetimeModalIsVisible) {
      return false;
    }

    lifetimeModalIsVisible = true;

    modals.openContextModal({
      modal: ModalKind.PRE_LIFETIME_WITH_WAIT,
      innerProps: {},
      centered: true,
      trapFocus: false,
      autoFocus: false,
      size: "500px",
      withCloseButton: false,
      padding: 0,
      closeOnEscape: shouldAlwaysShow,
      closeOnClickOutside: shouldAlwaysShow,
      onClose: () => {
        lifetimeModalIsVisible = false;
      },
    });
  }

  track({
    event: "open_lifetime_modal",
    properties: {
      source: source,
      ...additionalTrackProperties,
    },
  });

  return true;
}

export function ModalLifetime({ innerProps = {} }: ContextModalProps<{}>) {
  const { isProd, user } = useUser();
  const [selectedPlan, setSelectedPlan] = useState("yearly");
  // @ts-ignore
  const flagValue = window.ff_yearly_lifetime_low_high_pricing;

  const data = {
    title: "Pricing",
    subtitle: "Choose the plan that works best for you",
    plans: [
      {
        id: "free",
        title: "Preview",
        subtitle: "Explore around and discover if Inspotype is right for you.",
        cta: {
          yearly: "Continue Exploring",
          lifetime: "Continue Exploring",
        },
        onClick: () => {
          modals.closeAll();
        },
        features: [
          {
            text: "2 industries",
            icon: <IconBuildingStore size={16} />,
          },
          {
            text: "Max 3 moodboard bookmarks",
            icon: <IconBookmark size={16} />,
          },
          {
            text: "Basic moodboard customization",
            icon: <IconSquaresSelected size={16} />,
          },
          {
            text: "Basic font pairings and color palettes",
            icon: <IconLetterCase size={16} />,
          },
        ],
        pricing: {
          yearly: {
            label: "Free",
          },
          lifetime: {
            label: "Free",
          },
        },
      },
      {
        id: "pro",
        title: "Pro",
        subtitle: "Perfect for freelancers and productivized designers.",
        pricing: {
          yearly: {
            label: "/year",
            ...yearlyUrl(
              isProd,
              flagValue,
              { email: user.email, fullName: user.full_name },
              { userId: user.id },
            ),
          },
          lifetime: {
            label: "once",
            ...lifetimeUrl(isProd, flagValue, {
              email: user.email,
              fullName: user.full_name,
            }),
          },
        },
        cta: { yearly: "Get Full Access", lifetime: "Get Access Forever" },
        onClick: () => {
          track({
            event: "checkout_start",
            properties: {},
          });
          const url = data.plans.find((p) => p.id === "pro")?.pricing[
            selectedPlan
          ]?.link;

          if (url) {
            window.LemonSqueezy.Url.Open(url);
          }
        },
        features: [
          {
            text: "All industries",
            icon: <IconBuildingStore size={16} />,
          },
          {
            text: "Unlimited projects and bookmarks",
            icon: <IconBookmark size={16} />,
          },
          {
            text: "Upload custom images",
            icon: <IconPhotoUp size={16} />,
          },
          {
            text: "Edit text in cards",
            icon: <IconWriting size={16} />,
          },

          {
            text: `${fontStats.count.all} curated font pairings from Google, Fontshare, Adobe and ${fontStats.foundries.length} indie foundries`,
            icon: <IconLetterCase size={16} />,
          },
          {
            text: `${colorSchemes.length} curated color palettes, ready to use`,
            icon: <IconPalette size={16} />,
          },
          {
            text: "Local Fonts and Custom Colors Palettes",
            icon: <IconHome size={16} />,
          },
        ],
      },
      {
        id: "agency",
        title: "Agency",
        subtitle:
          "Efficient branding with an efficient pricing for agencies and small teams.",
        cta: {
          yearly: "Contact Us",
          lifetime: "Contact Us",
        },
        onClick: () => {
          // Open email client with pre-filled subject
          window.location.href =
            "mailto:hello@inspotype.com?subject=Agency License Inquiry&body=Hi, I'm interested in discussing agency licensing options for my team.";

          track({
            event: "agency_inquiry_click",
            properties: {},
          });
        },
        features: [
          {
            text: "Everything in PRO",
            icon: <IconSparkles size={16} />,
          },
          {
            text: "Flexible seat-based licensing",
            icon: <IconUsers size={16} />,
          },
          {
            text: "Volume discounts available",
            icon: <IconDiscount2 size={16} />,
          },
          {
            text: "Priority support",
            icon: <IconHeadset size={16} />,
          },
          {
            text: "Custom onboarding",
            icon: <IconUserCheck size={16} />,
          },
        ],
        pricing: {
          yearly: {
            label: "Let's talk",
          },
          lifetime: {
            label: "Let's talk",
          },
        },
      },
    ],
  };

  return (
    <>
      <ActionIcon
        pos={"absolute"}
        top={8}
        right={8}
        variant="transparent"
        color="gray"
        onClick={() => modals.closeAll()}
        style={{
          zIndex: 2,
          cursor: "pointer",
          opacity: 1,
        }}
      >
        <IconX size={20} />
      </ActionIcon>

      <Vertical
        bg={"rgba(245, 245, 245, 1)"}
        pt={"lg"}
        pb={"md"}
        px={"md"}
        gap={"sm"}
        fullH
        fullW
        center
      >
        <Vertical gap="sm" style={{ alignItems: "center" }}>
          <Title variant="headline-md" ta={"center"}>
            {data.title}
          </Title>
          <Text variant="paragraph-md" c="dimmed">
            {data.subtitle}
          </Text>

          <SegmentedControl
            size="xs"
            radius={"xs"}
            bg={"var(--mantine-color-gray-2)"}
            data={[
              {
                label: <Center style={{ gap: 4 }}>Yearly</Center>,
                value: "yearly",
              },
              {
                label: <Center style={{ gap: 4 }}>Lifetime</Center>,
                value: "lifetime",
              },
            ]}
            value={selectedPlan}
            onChange={(value) => {
              setSelectedPlan(value);
            }}
          />
        </Vertical>

        <Horizontal
          noWrap
          gap="md"
          align="stretch"
          w="100%"
          maw={1200}
          mx="auto"
        >
          {data.plans.map((plan) => (
            <Vertical
              key={plan.id}
              style={{
                flex: 1,
              }}
            >
              <Vertical
                bg="white"
                p="md"
                gap="sm"
                style={{
                  borderRadius: 12,
                  position: "relative",
                  ...(plan.id === "pro" && {
                    border: "2px solid var(--mantine-color-purple-6)",
                    boxShadow: "0 4px 10px rgba(103, 49, 255, 0.5)",
                  }),
                }}
              >
                <Horizontal fullW gap="xs" spaceBetween>
                  <Vertical gap="xs">
                    <Title variant="headline-sm">{plan.title}</Title>
                    <Text variant="paragraph-md" c="dimmed">
                      {plan.subtitle}
                    </Text>
                  </Vertical>
                </Horizontal>

                {plan.pricing && (
                  <Box>
                    <Horizontal gap={"xxs"} center>
                      {plan.id === "pro" && (
                        <Title variant="headline-md" fw={600}>
                          ${plan.pricing[selectedPlan].amount}
                        </Title>
                      )}
                      <Title style={{ fontFamily: "Clash Display" }} fw={100}>
                        {plan.pricing[selectedPlan].label}
                      </Title>
                    </Horizontal>
                  </Box>
                )}

                <InspoButton
                  theme={plan.id === "pro" ? "accent" : "dark"}
                  variant={plan.id === "free" ? "outline" : "filled"}
                  leftSection={plan.id === "pro" && <IconSparkles size={24} />}
                  h={44}
                  w={"100%"}
                  onClick={plan.onClick}
                >
                  {plan.cta[selectedPlan]}
                </InspoButton>

                <Vertical gap="xs" style={{ flex: 1 }}>
                  {plan.features.map((feature, i) => (
                    <Fragment key={i}>
                      <Horizontal gap="xs" noWrap align="flex-start">
                        <Box mt={2} opacity={0.5}>
                          {feature.icon}
                        </Box>
                        <Text variant="paragraph-md" lh={1.5}>
                          {feature.text}
                        </Text>
                      </Horizontal>
                      {i < plan.features.length - 1 && (
                        <Divider w={"100%"} opacity={0.3} />
                      )}
                    </Fragment>
                  ))}
                </Vertical>
              </Vertical>
            </Vertical>
          ))}
        </Horizontal>
      </Vertical>
    </>
  );
}

export function ModalPreLifetime({
  innerProps = { triggerFeatures: undefined },
}: ContextModalProps<{
  triggerFeatures:
    | "industrySection"
    | "uploadImage"
    | "saveMoodboardImg"
    | "addToFolder"
    | "addToNewFolder"
    | "colorPickerCustom"
    | undefined;
}>) {
  const data = {
    badge: "PRO",
    title: "You've Found a Pro Gem! ✨",
    subtitle:
      "This is one of our special pro-only features.\nReady to unlock the good stuff?",
    cta: "Show me all benefits of PRO",
    features: {
      [LifetimeModalTrigger.INDUSTRY_SECTION]: {
        name: "Mockup Card Industries",
        icon: <IconCards />,
      },
      [LifetimeModalTrigger.UPLOAD_IMAGE]: {
        name: "Upload your own images",
        icon: <IconPhotoUp />,
      },
      [LifetimeModalTrigger.SAVE_MOODBOARD_IMG]: {
        name: "Save Moodboard Image",
        icon: <IconBookmark />,
      },
      [LifetimeModalTrigger.ADD_TO_FOLDER]: {
        name: "Add to Folder",
        icon: <IconFolderSymlink />,
      },
      [LifetimeModalTrigger.ADD_TO_NEW_FOLDER]: {
        name: "Add to New Folder",
        icon: <IconFolderPlus />,
      },
      [LifetimeModalTrigger.COLOR_PICKER_CUSTOM]: {
        name: "Custom Color Picker",
        icon: <IconPalette />,
      },
      [LifetimeModalTrigger.CUSTOM_TEXTS]: {
        name: "Custom Text",
        icon: <IconWriting />,
      },
    },
  };

  const { user, isPro } = useUser();

  return (
    <Vertical fullW fullH gap={0} center>
      {/* dismiss button */}
      <ActionIcon
        pos={"absolute"}
        top={8}
        right={8}
        variant="transparent"
        color="gray"
        onClick={() => modals.closeAll()}
        style={{
          zIndex: 2,
          cursor: "pointer",
          opacity: 1,
        }}
      >
        <IconX size={20} />
      </ActionIcon>

      <Vertical fullW fullH gap={"sm"} center p={"md"}>
        <InspoBadge theme="purple" mx={"auto"}>
          {data.badge}
        </InspoBadge>

        {/* {Object.entries(data.features).map(([key, feature]) => (
          <Horizontal key={key} gap={"xs"} center>
            {feature.icon}
            <Text variant="paragraph-md">{feature.name}</Text>
          </Horizontal>
        ))} */}

        {innerProps.triggerFeatures && (
          <Horizontal gap={"xs"} center>
            {data.features[innerProps.triggerFeatures].icon}
            <Text variant="paragraph-md">
              {data.features[innerProps.triggerFeatures].name}
            </Text>
          </Horizontal>
        )}

        <Title variant="headline-sm" ta="center">
          {data.title}
        </Title>

        <Text variant="paragraph-lg" ta="center">
          {data.subtitle}
        </Text>

        <InspoButton
          theme="accent"
          iconPos="left"
          icon={<IconSparkles />}
          onClick={() => {
            openModalLifetimeIfNeeded({
              user,
              isPro,
              source: LifetimeModalTrigger.PRE_LIFETIME_WITH_WAIT_CTA,
            });
          }}
        >
          {data.cta}
        </InspoButton>
      </Vertical>
    </Vertical>
  );
}

export function ModalPreLifetimeWithWait({
  innerProps = {},
}: ContextModalProps<{}>) {
  const data = {
    title: [
      "Hey there, power user!",
      "Enjoying the pro features?",
      "You've got great taste!",
      "Having fun with Inspotype?",
      "Like what you see?",
      "Pro moves right there!",
    ],
    subtitle: [
      "Looks like you're getting the most out of Inspotype.\nWant to see what else we've got?",
      "We're happy to let you try these features.\nThere's plenty more where that came from!",
      "You're using some of our best features.\nCurious about the full package?",
      "Just a heads up - you're using some pro-level stuff.\nWant to see the whole toolkit?",
      "These pro features are just the tip of the iceberg.\nReady to dive deeper?",
      "You seem to know your way around here.\nWant to unlock everything?",
    ],
    cta: "Show me all benefits of PRO",
    timerLabel: "Or keep the exploration going...",
  };

  const { user, isPro } = useUser();

  // Move random selection to state initialization so it only happens once when modal opens
  const [selectedTitle] = useState(
    data.title[Math.floor(Math.random() * data.title.length)],
  );
  const [selectedSubtitle] = useState(
    data.subtitle[Math.floor(Math.random() * data.subtitle.length)],
  );

  const [timeRemaining, setTimeRemaining] = useState(
    LIFETIME_MODAL_TIMER_DURATION_SECONDS,
  );
  const canDismiss = timeRemaining <= 0;
  const showProgress = timeRemaining > 0;

  useEffect(() => {
    let startTime: number | null = null;
    let animationFrameId: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = (timestamp - startTime) / 1000; // Convert to seconds
      const newTimeRemaining = Math.max(
        LIFETIME_MODAL_TIMER_DURATION_SECONDS - elapsed,
        0,
      );

      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining > 0) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  const progressValue = Math.min(
    ((LIFETIME_MODAL_TIMER_DURATION_SECONDS - timeRemaining) /
      LIFETIME_MODAL_TIMER_DURATION_SECONDS) *
      100,
    100,
  );

  return (
    <Vertical fullW fullH gap={0} center pt={"md"}>
      <Box
        pos={"absolute"}
        top={8}
        right={8}
        style={{ zIndex: 2, position: "relative" }}
      >
        <RingProgress
          pos={"absolute"}
          top={-4}
          right={-4}
          size={36}
          thickness={2}
          roundCaps
          sections={[
            {
              value: progressValue,
              color: "gray",
            },
          ]}
          style={{
            opacity: showProgress ? 1 : 0,
            transition: "opacity 0.3s ease",
          }}
        />
        <ActionIcon
          variant="transparent"
          color="gray"
          onClick={() => canDismiss && modals.closeAll()}
          disabled={!canDismiss}
          style={{
            cursor: canDismiss ? "pointer" : "not-allowed",
            opacity: canDismiss ? 1 : 0.5,
            transition: "opacity 0.3s ease",
          }}
        >
          <IconX size={20} />
        </ActionIcon>
      </Box>

      <Vertical fullW fullH gap={"sm"} center p={"md"}>
        <Title variant="headline-sm" ta="center">
          {selectedTitle}
        </Title>

        <Text variant="paragraph-lg" ta="center" lh={1.6}>
          {selectedSubtitle}
        </Text>

        <InspoButton
          theme="accent"
          iconPos="left"
          icon={<IconSparkles />}
          onClick={() => {
            openModalLifetimeIfNeeded({
              user,
              isPro,
              source: LifetimeModalTrigger.PRE_LIFETIME_WITH_WAIT_CTA,
            });
          }}
        >
          {data.cta}
        </InspoButton>
      </Vertical>
    </Vertical>
  );
}
